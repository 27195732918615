const initialValues = {
}
const companyReducer = (state = initialValues, action) => {
    if (action.type === "addCompanyList") {
        return { ...state, companies: action.payload }
    } else if (action.type === "addClassManagersList") {
        return { ...state, classManagers: action.payload }
    } else if (action.type === "addCorpUsersList") {
        return { ...state, corpUsers: action.payload }
    }  else if (action.type === "addCorpUsers") {
        return { ...state, corpUsers: [...state.corpUsers,action.payload] }
    } else if (action.type === "addCompanyCount") {
        return { ...state, totalCompany:action.payload }
    } else if (action.type === "addClassManagerCount") {
        return { ...state, totalClassManager:action.payload }
    } else if (action.type === "addCorpUserCount") {
        return { ...state, totalCorpUser:action.payload }
    } else if (action.type === "addClassManagersNotOccupied") {
        return { ...state, classManagersNotOccupied:action.payload }
    } else {
        return state;
    } 
}


export default companyReducer;