const initialValues = {
     
}
const commonReducer = (state = initialValues, action) => {
    if (action.type === "addCountries") {
        return { ...state, countries: action.payload }
    } else if (action.type === "addCountriesDetails") {
        return { ...state, countriesDetails: action.payload }
    } else if (action.type === "addCourses") {
        return { ...state, courses: action.payload }
    } else if (action.type === "addRoles") {
        return { ...state, roles: action.payload }
    }  else if (action.type === "addQuiz") {
        return { ...state, quizs: action.payload }
    }  else if (action.type === "addCoursesArray") {
        return { ...state, coursesArray: action.payload }
    } else if (action.type === "addRolesArray") {
        return { ...state, rolesArray: action.payload }
    }  else if (action.type === "addQuizArray") {
        return { ...state, quizsArray: action.payload }
    }  else if (action.type === "addCoursesWithLabel") {
        return { ...state, courseWithLabel: action.payload }
    }  

    
    else {
        return state;
    } 
}

export default commonReducer;