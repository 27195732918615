const initialValues = {
  firstname: "",
  lastname: "",
  token: "",
  userid: "",
  isAuthenticated: false,
  region: "",
  countryname: "",
  username: "",
  countryCode: "",
  enable_global: "",
};
const authReducer = (state = initialValues, action) => {
  if (action.type === "saveData") {
    return {
      ...state,
      firstname: action.payload.first,
      lastname: action.payload.last,
      token: action.payload.token,
      userid: action.payload.userId,
      isAuthenticated: true,
      region: action.payload.region,
      countryname: action.payload.countryname,
      first: action.payload.userName.first,
      last: action.payload.userName.last,
      countryCode: action.payload.countryCode,
      enable_global: action.payload.enable_global
        ? action.payload.enable_global
        : "",
    };
  } else if (action.type === "removeData") {
    return {
      ...state,
      firstname: "",
      lastname: "",
      token: "",
      userid: "",
      isAuthenticated: false,
      region: "",
      countryname: "",
      countryCode: "",
      enable_global: "",
    };
  } else {
    return state;
  }
};

export default authReducer;
