import { lazy } from "react";

export const routes = {
  blank_page: {
    path: "/blank-page",
    component: lazy(() => import("../pages/BlankPage/BlankPage")),
    exact: true,
  },
  index: {
    path: "/",
    component: lazy(() => import("../pages/IndexPage/IndexPage")),
    exact: true,
  },
  // request_demo: {
  //   path: "/request-demo-page",
  //   component: lazy(() => import("../pages/RequestDemoPage/RequestDemoPage")),
  //   exact: true,
  // },
  // request_demo_visa: {
  //   path: "/visa/request-demo-page",
  //   component: lazy(() => import("../pages/RequestDemoPage/RequestDemoPagePromote")),
  //   exact: true,
  // },
  // request_demo_mastercard: {
  //   path: "/mastercard/request-demo-page",
  //   component: lazy(() => import("../pages/RequestDemoPage/RequestDemoPagePromote")),
  //   exact: true,
  // },
  saml: {
    path: "/saml/accops/:token?",
    component: lazy(() => import("../pages/SamlLoginPage/SamlLoginPage")),
    exact: true,
  },
  login_page: {
    path: "/login",
    component: lazy(() => import("../pages/LoginPage/LoginPage")),
    exact: true,
  },
  forget_page: {
    path: "/forgot-password",
    component: lazy(() =>
      import("../pages/ForgetPassWordPage/ForgetPassWordPage")
    ),
    exact: true,
  },
  reset_password: {
    path: "/password/reset",
    component: lazy(() => import("../pages/ResetPassword/ResetPassword")),
    exact: true,
  },
  sso_signin: {
    path : "/sso/:token/:id",
    component: lazy(() => import("../pages/ssoSigin/ssosignin")),
    exact: true,
  },
  // company_selection_page: {
  //   path:"/company-selection",
  //   component: lazy(() => import("../pages/CompanySelectionPage/CompanySelection")),
  //   exact: true,
  // },
};

export const renderRoutes = Object.entries(routes);

export const privateroutes = {
  dashboard: {
    path: '/dashboard',
    component: lazy(() => import('../pages/Dashboard/Dashboard')),
    exact: true,
    menuname: 'Dashboard',
  },
  all_courses: {
    path: '/courses-content',
    component: lazy(() => import('../pages/coursecontent/coursecontents')),
    exact: true,
    menuname: 'Courses',
  },
  teams: {
    path: '/classrooms',
    component: lazy(() => import('../pages/Teams/Teams')),
    exact: true,
    menuname: 'Classrooms',
  },
  create_team: {
    path: '/create-classroom',
    component: lazy(() => import('../pages/CreateTeam/CreateTeam')),
    exact: true,
    menuname: 'Classrooms',
  },
  Edit_team: {
    path: '/edit-classroom/:id',
    component: lazy(() => import('../pages/EditTeam/EditTeam')),
    exact: true,
    menuname: 'Classrooms',
  },
  Team_Report: {
    path: '/batch-report/:id',
    component: lazy(() => import('../pages/TeamReport/TeamReport')),
    exact: true,
    menuname: 'Classrooms',
  },
  users: {
    path: '/users',
    component: lazy(() => import('../pages/Users/Users')),
    exact: true,
    menuname: 'Users',
  },
  user_quick_view: {
    path: '/user-quick-view/:id',
    component: lazy(() => import('../pages/UserQuickView/UserQuickView')),
    exact: true,
    menuname: 'Users',
  },
  add_user: {
    path: '/add-user',
    component: lazy(() => import('../pages/AddUser/AddUser')),
    exact: true,
    menuname: 'Users',
  },
  edit_user: {
    path: '/edit-user/:id',
    component: lazy(() => import('../pages/EditUser/EditUser')),
    exact: true,
    menuname: 'Users',
  },
  courses: {
    path: '/courses',
    component: lazy(() => import('../pages/Courses/Courses')),
    exact: true,
    menuname: 'Learning Paths',
  },
  // subscriptions: {
  //   path: "/subscriptions",
  //   component: lazy(() => import("../pages/Subscriptions/Subcriptions")),
  //   exact: true,
  //   menuname: 'Subscriptions'
  // },
  courses_select: {
    path: '/courses-select',
    component: lazy(() => import('../pages/CourseSelect/CourseSelect')),
    exact: true,
    menuname: 'Courses',
  },
  // learning: {
  //   path: "/learningpath",
  //   component: lazy(() => import("../pages/Learning/Learning")),
  //   exact: true,
  //   menuname: 'Learnign Path'
  // },
  learning_select: {
    path: '/learning-select',
    component: lazy(() => import('../pages/LearningSelect/LearningSelect')),
    exact: true,
    menuname: 'Learnign Path',
  },
  create_learning: {
    path: '/create-learning',
    component: lazy(() => import('../pages/CreateLearning/CreateLearning')),
    exact: true,
    menuname: 'Learning Path',
  },
  edit_learning: {
    path: '/edit-learning/:id',
    component: lazy(() => import('../pages/EditLearning/EditLearning')),
    exact: true,
    menuname: 'Learning Path',
  },
  // view_report_learning: {
  //   path: "/learning/view-report/:id",
  //   component: lazy(() => import("../pages/LearningReport/LearningReport")),
  //   exact: true,
  //   menuname: 'Learning Path'
  // },
  training: {
    path: '/training',
    component: lazy(() => import('../pages/Training/Training')),
    exact: true,
    menuname: 'Training',
  },
  training_select: {
    path: '/training-select',
    component: lazy(() => import('../pages/TrainingSelect/TrainingSelect')),
    exact: true,
    menuname: 'Training',
  },
  create_training: {
    path: '/create-training',
    component: lazy(() => import('../pages/CreateTraining/CreateTraining')),
    exact: true,
    menuname: 'Training',
  },
  duplicate_copy_training: {
    path: '/duplicate-training',
    component: lazy(() =>
      import('../pages/DuplicateTraining/DuplicateTraining')
    ),
    exact: true,
    menuname: 'Training',
  },
  roles_privileges: {
    path: '/roles-privileges',
    component: lazy(() => import('../pages/RolesPrivileges/RolesPrivileges')),
    exact: true,
    menuname: 'Roles',
  },
  add_roles_privileges: {
    path: '/add-roles-privileges',
    component: lazy(() => import('../pages/AddRoles/AddRoles')),
    exact: true,
    menuname: 'Roles',
  },
  edit_roles_privileges: {
    path: '/edit-roles-privileges/:id',
    component: lazy(() => import('../pages/EditRoles/EditRoles')),
    exact: true,
    menuname: 'Roles',
  },
  inbox: {
    path: '/inbox',
    component: lazy(() => import('../pages/Inbox/Inbox')),
    exact: true,
    menuname: 'Indox',
  },
  inbox_view: {
    path: '/inbox-view',
    component: lazy(() => import('../pages/InboxView/InboxView')),
    exact: true,
    menuname: 'Indox',
  },
  email_notification: {
    path: '/email-notification',
    component: lazy(() =>
      import('../pages/EmailNotification/EmailPreferences')
    ),
    exact: true,
    menuname: 'Email Notification',
  },
  setting_page: {
    path: '/setting-page',
    component: lazy(() => import('../pages/SettingPage/SettingPage')),
    exact: true,
    menuname: 'Settings',
  },
  practice_test_page: {
    path: '/reports/practice-test',
    component: lazy(() => import('../pages/ReportsPage/PracticeTest')),
    exact: true,
    menuname: 'Analytics',
  },
  activity_report_page: {
    path: '/reports/activity-report',
    component: lazy(() => import('../pages/ReportsPage/ActivityReport')),
    exact: true,
    menuname: 'Analytics',
  },
  live_lab_report_page: {
    path: '/reports/live-labs',
    component: lazy(() => import('../pages/ReportsPage/HandsOnLabReport')),
    exact: true,
    menuname: 'Analytics',
  },
  lab_validation_report_page: {
    path: '/reports/labs-validation',
    component: lazy(() => import('../pages/ReportsPage/LabValidationReport')),
    exact: true,
    menuname: 'Analytics',
  },
  user_lab_behaviour_report_page: {
    path: '/reports/lab-behaviour-report',
    component: lazy(() =>
      import('../pages/UserBehaviourLabReport/UserBehaviourLabReport')
    ),
    exact: true,
    menuname: 'Analytics',
  },
  user_sandbox_behaviour_report_page: {
    path: '/reports/sandbox-behaviour-report',
    component: lazy(() =>
      import('../pages/UserBehaviourSandboxReport/UserBehaviourSandboxReport')
    ),
    exact: true,
    menuname: 'Analytics',
  },
  live_lab_report_dashboard: {
    path: '/reports/live-labs-dashboard',
    component: lazy(() =>
      import('../pages/LiveLabsDashboard/LiveLabsDashboard')
    ),
    exact: true,
    menuname: 'Analytics',
  },
  live_sandbox_report_dashboard: {
    path: '/reports/sandbox-dashboard',
    component: lazy(() =>
      import('../pages/LiveSandboxDashboard/LiveSandboxDashboard')
    ),
    exact: true,
    menuname: 'Analytics',
  },
  sandbox_report_page: {
    path: '/reports/sandbox',
    component: lazy(() => import('../pages/ReportsPage/SandboxReport')),
    exact: true,
    menuname: 'Analytics',
  },
  custom_labs_report_page: {
    path: '/reports/custom-labs',
    component: lazy(() => import('../pages/ReportsPage/CustomLabsReport')),
    exact: true,
    menuname: 'Analytics',
  },
  online_test_page: {
    path: '/reports/online-test',
    component: lazy(() => import('../pages/ReportsPage/OnlineTest')),
    exact: true,
    menuname: 'Analytics',
  },
  Enrollment_report_page: {
    path: '/reports/enrollment-report',
    component: lazy(() => import('../pages/ReportsPage/EnrollmentReport')),
    exact: true,
    menuname: 'Analytics',
  },
  overall_progress_report_page: {
    path: '/reports/overall-progress',
    component: lazy(() => import('../pages/OverallProgressPage/OverallProgress')),
    exact: true,
    menuname: 'Analytics',
  },
  // Certificate_report_page: {
  //   path: "/reports/certificate-report",
  //   component: lazy(() => import("../pages/ReportsPage/CertificateReport")),
  //   exact: true,
  //   menuname: 'Analytics'
  // },
  // <---- user-pages ----->
  home_user: {
    path: '/home-user',
    component: lazy(() =>
      import('../pages/UserPages/HomeUserPage/HomeUserPage')
    ),
    exact: true,
    menuname: 'Dashboard',
  },
  courses_user: {
    path: '/courses-user-page',
    component: lazy(() =>
      import('../pages/UserPages/CoursesUserPage/CoursesUserPage')
    ),
    exact: true,
    menuname: 'Courses',
  },
  courses_user_requested: {
    path: '/requested-courses',
    component: lazy(() =>
      import('../pages/UserPages/RequestedCourses/RequestedCourses')
    ),
    exact: true,
    menuname: 'Courses',
  },
  inbox_user: {
    path: '/inbox-user-page',
    component: lazy(() =>
      import('../pages/UserPages/InboxUserPage/InboxUserPage')
    ),
    exact: true,
    menuname: 'Inbox',
  },
  inbox_user_view: {
    path: '/inbox-user-view',
    component: lazy(() =>
      import('../pages/UserPages/InboxUserViewPage/InboxUserViewPage')
    ),
    exact: true,
    menuname: 'Inbox',
  },
  setting_user_page: {
    path: '/setting-user-page',
    component: lazy(() =>
      import('../pages/UserPages/SettingUserPage/SettingUserPage')
    ),
    exact: true,
    menuname: 'Setting Page',
  },
  superior_user_page: {
    path: '/superior-user-page',
    component: lazy(() =>
      import('../pages/UserPages/SuperiorUserPage/SuperiorUserPage')
    ),
    exact: true,
    menuname: 'Superior Page',
  },
  notification_user_page: {
    path: '/notification-user-page',
    component: lazy(() =>
      import('../pages/UserPages/NotificationUserPage/NotificationUserPage')
    ),
    exact: true,
    menuname: 'Notification',
  },
  group_chat_user: {
    path: '/group-chat-user',
    component: lazy(() =>
      import('../pages/UserPages/GroupChatUserPage/GroupChatUserPage')
    ),
    exact: true,
    menuname: 'Group',
  },
  my_training_user: {
    path: '/my-training-user',
    component: lazy(() =>
      import('../pages/UserPages/MyTrainingUserPage/MyTrainingUserPage')
    ),
    exact: true,
    menuname: 'My Training',
  },
  learning_paths_user: {
    path: '/learning-paths-user/:path_id',
    component: lazy(() =>
      import('../pages/UserPages/LearningPathsUser/LearningPathsUser')
    ),
    exact: true,
    menuname: 'Learning Path',
  },
  training_plans_user: {
    path: '/training-plans-user',
    component: lazy(() =>
      import('../pages/UserPages/TrainingPlansUserPage/TrainingPlansUserPage')
    ),
    exact: true,
    menuname: 'Training',
  },
  idp_OAuth: {
    path: '/authorize/revan360',
    component: lazy(() => import('../pages/OAuth/OAuth')),
    exact: true,
  },
  // admin
  // categories_page: {
  //   path: "/categories",
  //   component: lazy(() => import("../pages/Categories/Categories")),
  //   exact: true,
  //   menuname: 'LMS'
  // },
  // courses: {
  //   path: "/courses",
  //   component: lazy(() => import("../pages/AdminCourses/AdminCourses")),
  //   exact: true,
  //   menuname: 'LMS'
  // },
  // learning: {
  //   path: "/learning",
  //   component: lazy(() => import("../pages/AdminLearning/AdminLearning")),
  //   exact: true,
  //   menuname: 'LMS'
  // },
  // learning_moderation: {
  //   path: "/coursemoderation/:id",
  //   component: lazy(() => import("../pages/LearningPathModerationPage/LearningPathModerationPage")),
  //   exact: true,
  //   menuname: 'LMS'
  // },
  // create_learning: {
  //   path: "/admin-create-learning",
  //   component: lazy(() => import("../pages/AdminCreateLearning/AdminCreateLearning")),
  //   exact: true,
  //   menuname: 'LMS'
  // },
  // update_learning: {
  //   path: "/update-learning/:id",
  //   component: lazy(() => import("../pages/AdminCreateLearning/AdminCreateLearning")),
  //   exact: true,
  //   menuname: 'LMS'
  // },
  // reading_files_page: {
  //   path: "/reading",
  //   component: lazy(() => import("../pages/ReadingFilesPage/ReadingFilesPage")),
  //   exact: true,
  //   menuname: 'LMS'
  // },
  // add_reading_files_page: {
  //   path: "/reading/files/create",
  //   component: lazy(() => import("../pages/AddReadingFiles/AddReadingFilesPage")),
  //   exact: true,
  //   menuname: 'LMS'
  // },
  // edit_reading_files_page: {
  //   path: "/reading/files/edit/:id",
  //   component: lazy(() => import("../pages/EditReadingFiles/EditReadingFilesPage")),
  //   exact: true,
  //   menuname: 'LMS'
  // },
  // company: {
  //   path: "/companies",
  //   component: lazy(() => import("../pages/Company/Company")),
  //   exact: true,
  //   menuname: 'Company'
  // },
  // companyView: {
  //   path: "/company/view",
  //   component: lazy(() => import("../pages/CompanyView/CompanyView")),
  //   exact: true,
  //   menuname: 'Company'
  // },
  // add_company: {
  //   path: "/add-company",
  //   component: lazy(() => import("../pages/AddCompany/AddCompany")),
  //   exact: true,
  //   menuname: 'Company'
  // },
  // class_managers: {
  //   path: "/class-managers",
  //   component: lazy(() => import("../pages/ClassManagers/ClassManagers")),
  //   exact: true,
  //   menuname: 'Company'
  // },
  // class_managers_edit: {
  //   path: "/class-managers/add",
  //   component: lazy(() => import("../pages/ClassManagersAdd/ClassManagersAdd")),
  //   exact: true,
  //   menuname: 'Company'
  // },

  // subscription_bundle_user: {
  //   path: "/subscription-bundle-user/:plan_id",
  //   component: lazy(() =>
  //     import(
  //       "../pages/UserPages/SubscriptionBundleUserPage/SubscriptionBundleUserPage"
  //     )
  //   ),
  //   exact: true,
  //   menuname: 'Subscriptions'
  // },
};

export const privateRenderRoutes = Object.entries(privateroutes);

export const adminroutes = {
  categories_page: {
    path: "/categories",
    component: lazy(() => import("../pages/Categories/Categories")),
    exact: true,
    menuname: 'LMS'
  },
  courses: {
    path: "/content",
    component: lazy(() => import("../pages/AdminCourses/AdminCourses")),
    exact: true,
    menuname: 'LMS'
  },
  learning: {
    path: "/courses",
    component: lazy(() => import("../pages/AdminLearning/AdminLearning")),
    exact: true,
    menuname: 'LMS'
  },
  learning_moderation: {
    path: "/coursemoderation/:id",
    component: lazy(() => import("../pages/LearningPathModerationPage/LearningPathModerationPage")),
    exact: true,
    menuname: 'LMS'
  },
  create_learning: {
    path: "/admin-create-courses",
    component: lazy(() => import("../pages/AdminCreateLearning/AdminCreateLearning")),
    exact: true,
    menuname: 'LMS'
  },
  update_learning: {
    path: "/update-courses/:id",
    component: lazy(() => import("../pages/AdminCreateLearning/AdminCreateLearning")),
    exact: true,
    menuname: 'LMS'
  },
  reading_files_page: {
    path: "/reading",
    component: lazy(() => import("../pages/ReadingFilesPage/ReadingFilesPage")),
    exact: true,
    menuname: 'LMS'
  },
  add_reading_files_page: {
    path: "/reading/files/create",
    component: lazy(() => import("../pages/AddReadingFiles/AddReadingFilesPage")),
    exact: true,
    menuname: 'LMS'
  },
  edit_reading_files_page: {
    path: "/reading/files/edit/:id",
    component: lazy(() => import("../pages/EditReadingFiles/EditReadingFilesPage")),
    exact: true,
    menuname: 'LMS'
  },
  questions: {
    path: "/questions",
    component: lazy(() => import("../pages/Questions/Questions")),
    exact: true,
    menuname: 'LMS'
  },
  singlechoice: {
    path: "/questions/singlechoice",
    component: lazy(() => import("../components/Questions/SingleChoice")),
    exact: true,
    menuname: 'LMS'
  },
  edit_singlechoice: {
    path: "/questions/singlechoice/:id",
    component: lazy(() => import("../components/Questions/EditSingleChoice")),
    exact: true,
    menuname: 'LMS'
  },
  multiplechoice: {
    path: "/questions/multiplechoice",
    component: lazy(() => import("../components/Questions/MultipleChoice")),
    exact: true,
    menuname: 'LMS'
  },
  edit_multiplechoice: {
    path: "/questions/multiplechoice/:id",
    component: lazy(() => import("../components/Questions/EditMultipleChoice")),
    exact: true,
    menuname: 'LMS'
  },
  truefalse: {
    path: "/questions/true-or-false",
    component: lazy(() => import("../components/Questions/TrueFalse")),
    exact: true,
    menuname: 'LMS'
  },
  edit_truefalse: {
    path: "/questions/true-or-false/:id",
    component: lazy(() => import("../components/Questions/EditTrueFalse")),
    exact: true,
    menuname: 'LMS'
  },
  matching: {
    path: "/questions/matching",
    component: lazy(() => import("../components/Questions/Matching")),
    exact: true,
    menuname: 'LMS'
  },
  edit_matching: {
    path: "/questions/matching/:id",
    component: lazy(() => import("../components/Questions/EditMatching")),
    exact: true,
    menuname: 'LMS'
  },
  arrange_order: {
    path: "/questions/arrange-order",
    component: lazy(() => import("../components/Questions/DragAndDropArrange")),
    exact: true,
    menuname: 'LMS'
  },
  edit_arrange_order: {
    path: "/questions/arrange-order/:id",
    component: lazy(() => import("../components/Questions/EditDragAndDropArrange")),
    exact: true,
    menuname: 'LMS'
  },
  align_answers: {
    path: "/questions/align-order",
    component: lazy(() => import("../components/Questions/DragAndDropAllign")),
    exact: true,
    menuname: 'LMS'
  },
  edit_align_answers: {
    path: "/questions/align-order/:id",
    component: lazy(() => import("../components/Questions/EditDragAndDropAllign")),
    exact: true,
    menuname: 'LMS'
  },
  quiz: {
    path: "/quiz",
    component: lazy(() => import("../pages/Quiz/Quiz")),
    exact: true,
    menuname: 'LMS'
  },
  company: {
    path: "/companies",
    component: lazy(() => import("../pages/Company/Company")),
    exact: true,
    menuname: 'Company'
  },
  companyView: {
    path: "/company/view",
    component: lazy(() => import("../pages/CompanyView/CompanyView")),
    exact: true,
    menuname: 'Company'
  },
  add_company: {
    path: "/add-company",
    component: lazy(() => import("../pages/AddCompany/AddCompany")),
    exact: true,
    menuname: 'Company'
  },
  class_managers: {
    path: "/class-managers",
    component: lazy(() => import("../pages/ClassManagers/ClassManagers")),
    exact: true,
    menuname: 'Company'
  },
  class_managers_edit: {
    path: "/class-managers/add",
    component: lazy(() => import("../pages/ClassManagersAdd/ClassManagersAdd")),
    exact: true,
    menuname: 'Company'
  },
  enrollment_report: {
    path: '/enrollment-reports',
    component: lazy(() => import('../pages/EnrollmentReport/EnrollmentReport')),
    exact: true,
    menuname: 'Reports',
  },
  practice_report: {
    path: "/practice-reports",
    component: lazy(() => import("../pages/PracticeReport/PracticeReport")),
    exact: true,
    menuname: 'Reports'
  },
  practice_report_view: {
    path: "/practice-reports/view",
    component: lazy(() => import("../pages/PracticeReport/PracticeReportView")),
    exact: true,
    menuname: 'Reports'
  },
  online_course_report: {
    path: "/online-course-reports",
    component: lazy(() => import("../pages/OnlineCourseReport/OnlineCourseReport")),
    exact: true,
    menuname: 'Reports'
  },
  // enrollment_report: {
  //   path: "/enrollment-reports",
  //   component: lazy(() => import("../pages/EnrollmentReport/EnrollmentReport")),
  //   exact: true,
  //   menuname: 'Reports'
  // }
  company_reports: {
    path: "/company-reports",
    component: lazy(() => import("../pages/CompanyReports/CompanyReports")),
    exact: true,
    menuname: 'Reports'
  },
  provider_course_reports: {
    path: "/provider-course-report",
    component: lazy(() => import("../pages/ProviderCourseReports/ProviderCourseReports")),
    exact: true,
    menuname: 'Reports'
  },
  forget_page: {
    path: "/admin-forgot-password",
    component: lazy(() => import("../pages/AdminForgetPassWordPage/AdminForgetPassWordPage")),
    exact: true,
    menuname: 'Settings' 
  },
}

export const adminRenderRoutes = Object.entries(adminroutes);
