const initialValues = {
  data: [
    {
      lab: [],
    },
    {
      aws: [],
    },
    {
      oc: [],
    },
    {
      pt: [],
    },
    {
      rm: [],
    },
    {
      sb: [],
    },
  ],
};

const learningReducer = (state = initialValues, action) => {
  if (action.type === "saveLearningData") {
    const { id, name } = action.payload;
    if (name === "Lab") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("lab")) {
            return {
              ...item,
              lab: [...item.lab, id],
            };
          }
          return item;
        }),
      };
    } else if (name === "AWS Digital Courses") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("aws")) {
            return {
              ...item,
              aws: [...item.aws, id],
            };
          }
          return item;
        }),
      };
    } else if (name === "Online Course(OC)") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("oc")) {
            return {
              ...item,
              oc: [...item.oc, id],
            };
          }
          return item;
        }),
      };
    } else if (name === "Practice Test(PT)") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("pt")) {
            return {
              ...item,
              pt: [...item.pt, id],
            };
          }
          return item;
        }),
      };
    } else if (name === "Reading Materials/Files") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("rm")) {
            return {
              ...item,
              rm: [...item.rm, id],
            };
          }
          return item;
        }),
      };
    } else {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("sb")) {
            return {
              ...item,
              sb: [...item.sb, id],
            };
          }
          return item;
        }),
      };
    }
  } else if (action.type === "trimLearningData") {
    const { id, name } = action.payload;
    if (name === "Lab") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("lab")) { 
            return {
              ...item,
              lab: item.lab.filter((ele) => ele.id !== id.id),
            };
          }
          return item;
        }),
      };
    } else if (name === "AWS Digital Courses") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("aws")) {
            return {
              ...item,
              aws: item.aws.filter((ele) => ele.id !== id.id),
            };
          }
          return item;
        }),
      };
    } else if (name === "Online Course(OC)") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("oc")) {
            return {
              ...item,
              oc: item.oc.filter((ele) => ele.id !== id.id),
            };
          }
          return item;
        }),
      };
    } else if (name === "Practice Test(PT)") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("pt")) {
            return {
              ...item,
              pt: item.pt.filter((ele) => ele.id !== id.id),
            };
          }
          return item;
        }),
      };
    }  else if (name === "Reading Materials/Files") {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("rm")) {
            return {
              ...item,
              rm: item.rm.filter((ele) => ele.id !== id.id),
            };
          }
          return item;
        }),
      };
    } else  {
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.hasOwnProperty("sb")) {
            return {
              ...item,
              sb: item.sb.filter((ele) => ele.id !== id.id),
            };
          }
          return item;
        }),
      };
    }
  } else if (action.type === "removeLearningData") {
    return {
      ...state,
      data: initialValues.data,
    };
  } else if (action.type === "updateLearningData") {
    const { name } = action.payload;
    // console.log(name);
    if (name === "Lab") {
      return {
        ...state,
        data: [{ lab: [] }, ...state.data.slice(1)],
      };
    } else if (name === "AWS Digital Courses") {
      return {
        ...state,
        data: [...state.data.slice(0, 1), { aws: [] }, ...state.data.slice(2)],
      };
    } else if (name === "Online Course(OC)") {
      return {
        ...state,
        data: [...state.data.slice(0, 2), { oc: [] }, ...state.data.slice(3)],
      };
    } else if (name === "Practice Test(PT)") {
      return {
        ...state,
        data: [...state.data.slice(0, 3), { pt: [] }, ...state.data.slice(4)],
      };
    } else if (name === "Reading Materials/Files")  {
      return {
        ...state,
        data: [...state.data.slice(0, 4), { rm: [] }, ...state.data.slice(5)],
      };
    } else if (name === "Sandbox")  {
      return {
        ...state,
        data: [...state.data.slice(0, 5), { rm: [] }, ...state.data.slice(6)],
      };
    }
  } else {
    return state;
  }
};

export default learningReducer;
