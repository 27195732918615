import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import companyReducer from "./companyReducer";
import commonReducer from "./commonReducer";
import authReducer from "./authReducer";

import { combineReducers } from "redux";
import readingReducer from "./readingPageReducer";
import learningReducer from "./learingPath";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "companyReducer",
    "commonReducer",
    "reportReducer",
    "authReducer",
    "readingReducer",
  ],
};

const companyPersistConfig = {
  key: "companyReducer",
  storage,
};

// const commonPersistConfig = {
//   key: 'commonReducer',
//   storage,
// }

const authPersistConfig = {
  key: "authReducer",
  storage,
};

const readingPersistConfig = {
  key: "readingReducer",
  storage,
};
const learingPersistConfig = {
  key: "learningReducer",
  storage,
};
const commonPersistConfig = {
  key: "commonReducer",
  storage,
};

const reportPersistConfig = {
  key: "reportReducer",
  storage,
};

const rootReducer = combineReducers({
  companyReducer: persistReducer(companyPersistConfig, companyReducer),
  commonReducer: persistReducer(commonPersistConfig, commonReducer),
  authReducer: persistReducer(authPersistConfig, authReducer),
  readingReducer: persistReducer(readingPersistConfig, readingReducer),
  learningReducer: persistReducer(reportPersistConfig, learningReducer),
});
const persistedReducer = persistReducer(learingPersistConfig, rootReducer);
export default persistedReducer;
