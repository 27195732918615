const initialValues = {
  data: [],
  backupdata: [],
  totalCount: "",
};
const readingReducer = (state = initialValues, action) => {
  if (action.type === "saveData") {
    return {
      ...state,
      data: action.payload.files,
      backupdata: action.payload.files,
      categories: [],
      totalCount: action.payload.totalCount,
    };
  } else if (action.type === "removeData") {
    return {
      ...state,
      data: [],
      backupdata: [],
      categories: [],
      totalCount: "",
    };
  } else if (action.type === "saveCategories") {
    return {
      ...state,
      categories: action.payload,
    };
  } else {
    return state;
  }
};

export default readingReducer;
